@import "../../../../style.scss";

.container {
  font-size: 2.5rem;
  height: 100%;
  background: $primary-bg-color;
  margin: 0 auto;
}

.mainContainer {
  max-width: 1360px;
  margin: 0 auto;
  height: 100%;
  padding: 7rem 1rem;

  @media screen and (max-width: 1380px) {
    max-width: 1190px;
  }

  @media screen and (max-width: 1220px) {
    max-width: 1020px;
  }

  @media screen and (max-width: 1040px) {
    max-width: 850px;
  }

  @media screen and (max-width: 870px) {
    max-width: 680px;
  }

  @media screen and (max-width: 700px) {
    max-width: 510px;
  }

  @media screen and (max-width: 530px) {
    max-width: 375px;
  }

  @media screen and (max-width: 330px) {
    padding: 7rem 0;
  }
}

.glassContainer {
  min-height: 100%;
}

.productButton {
  font-size: 1.7rem !important;
  color: white !important;
  min-width: 125px !important;
  display: inline-block !important;
  font-family: $primary-header-font !important;
  padding: 0.5rem 2rem !important;
  background-color: $primary-indicator-color-2 !important;
  text-transform: none !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

.productIconButton {
  font-size: 1.7rem !important;
  color: white !important;
  min-width: 50px !important;
  display: inline-block !important;
  font-family: $primary-header-font !important;
  padding: 0.5rem 2rem !important;
  background-color: $primary-indicator-color-2 !important;
  text-transform: none !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

.productMenuList {
  background-color: #f5fbff !important;
}

.productMenuListItem {
  font-size: 1.2rem !important;
  font-family: $primary-header-font !important;
}

.titleContainer {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 530px) {
    display: none;
  }
}

.navigateIconContainer {
  cursor: pointer;
  padding: 0 2rem;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}

.titleMainContainer {
  display: flex;
  display: -ms-flexbox;
  direction: ltr;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  position: relative;
  border-bottom: 1px solid hsla(0, 0%, 81%, 1);
}

.titleMainContainer::-webkit-scrollbar {
  display: none;
}

.insetTitleContainer {
  border-right: 1px solid hsla(0, 0%, 81%, 1);
  width: 100%;
  height: 100%;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
}

.title:last-child {
  .insetTitleContainer {
    border-right: none;
  }
}

.titleMainContainer:last-child {
  border-right: none;
}

.insetTitle {
  margin-right: 0.7rem;
}

.title {
  font-family: $primary-header-font;
  font-size: $primary-product-form-label-font-size;
  font-weight: 500;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -ms-flex-direction: column;
  width: 170px;
  min-width: 170px;
  text-align: center;
  cursor: pointer;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  padding: 2rem 0.5rem;
  color: $primary-product-title-color;
  transition: all 0.4s ease;
  word-spacing: 9999999px;
  z-index: 1;
  animation-delay: 0.4s;
}

.title:hover {
  color: $primary-divider-color;
}

.title[aria-expanded="true"] {
  transition-delay: 0.6s;
  color: $primary-font-color-dk-bg;
}

.contentContainer {
  position: relative;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.content {
  display: none;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.content[aria-expanded="true"] {
  display: block;
  opacity: 1;
  visibility: visible;
}

.indicator {
  position: absolute;
  top: 0;
  top: 8px;
  left: 0;
  width: 170px;
  min-width: 170px;
  height: 80%;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.indicatorMain {
  z-index: 0;
  height: 100%;
  width: 160px;
  min-width: 160px;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background: $primary-indicator-color-1;
  background: -moz-linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  background: linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$primary-indicator-color-1, endColorstr=$primary-indicator-color-2,GradientType=1 );
}

.navigateIcon {
  font-size: 3.5rem !important;
  color: $primary-font-color-dk-bg !important;
  background: $primary-indicator-color-1;
  background: -moz-linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  background: linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  border-radius: 50%;
}
