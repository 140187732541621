@import "../../../style.scss";

.titleContainer {
  font-family: $primary-header-font;
  font-size: 3.5rem;
  color: $primary-title-color;
  text-align: center;
}

.contentContainer {
  font-family: $primary-content-font;
  font-size: 1.7rem;
  color: $primary-font-color;
  margin: 0 auto;
  margin-top: 2rem;
  font-weight: 300;
  text-align: center;
  max-width: 600px;
}

.flipableCardContainer {
  max-width: 1400px;
  margin: 10rem auto;
  padding: 0 2rem;
}

.cardImg {
  font-size: 150px !important;
  color: inherit !important;
}

.saleCarouselContainer {
  position: relative;
  background: $primary-gradient;
  padding-top: 5rem;
  @media (max-width: 1040px) {
    padding-top: 0;
  }
}

.saleMainContainer {
  padding-top: 2rem;
  position: relative;
  background: $primary-bg-color;
}

.saleMainContainer[aria-expanded="true"] {
  padding-top: 2rem;
  position: relative;
  background: $primary-bg-color;
}
