@import "../../../style.scss";

.carouselMainContainer {
  height: 100%;
}

.carouselContainer1 {
  position: relative;
  background: $primary-gradient;
  padding-top: 5rem;
  @media (max-width: 1040px) {
    padding-top: 0;
  }
}

.carouselContainer2 {
  background-color: $primary-color-light-1;
  top: 100px;
  position: fixed;
  height: 950px;
  width: 100%;
  left: 0px;
  z-index: -2;
}

.saleMainContainer {
  padding-top: 2rem;
  position: relative;
  background: $primary-bg-color;
}

.saleMainContainer[aria-expanded="true"] {
  padding-top: 2rem;
  position: relative;
  background: $primary-bg-color;
  pointer-events: none !important;
}

.multipleCardContainer {
  padding: 5% 0;
}

.damageAssistanceContainer {
  padding: 10rem 0;

  @media screen and (max-width: 768px) {
    padding: 5rem 0;
  }

  @media screen and (min-width: 600px) and (max-width: 919px) {
    padding: 10% 0;
  }
}

.multipleCardTitleContainer {
  font-size: 35px;
  font-weight: bold;
  color: #3b3b3b;
}

.productInfoBtn {
  color: #2792d6 !important;
  background-color: white !important;
  border-radius: 60px !important;
  font-size: 1vw !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: none !important;
  margin: 0 20px !important;
  box-shadow: none !important;
  border: 3px solid #2792d6 !important;
  font-weight: bold !important;
}

.getOfferBtn {
  color: white !important;
  background-color: #2792d6 !important;
  border-radius: 60px !important;
  font-size: 1vw !important;
  text-transform: none !important;
  margin: 0 20px !important;
  box-shadow: none !important;
  border: 3px solid #2792d6 !important;
}

.imgSlider {
  position: relative;
  width: 100%;
  height: 65vh;
}

.slide {
  position: absolute;
  width: 100%;
  opacity: 1;
  height: 100%;
}

.img {
  width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  position: absolute;
  top: 0%;
  padding: 1% 10%;
  width: 100%;
}

.infoTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 35px;
  font-weight: bold;
  padding: 20px 0;
}

.infoContent {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0% 20%;
}

.infoCards {
  position: absolute;
  top: 30%;
  padding: 1% 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoButtons {
  position: absolute;
  top: 84%;
  padding: 1% 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconContainer {
  padding: 20px 0;
}

.vehicleIcon {
  font-size: 15rem !important;
  font-weight: 400 !important;
  color: $primary-title-color !important;
}

.healthIcon {
  font-size: 15rem !important;
  font-weight: 400 !important;
  color: $primary-title-color !important;
}

.iconContent {
  padding: 10px 0;
  font-size: 14px;
  font-weight: normal !important;
  margin: 0 40px !important;
}

.productCardsMainContainer {
  padding: 12rem 0;
  // background-color: $primary-bg-color;

  @media screen and (max-width: 1040px) {
    padding: 4rem 0 5rem;
  }
}

.productCardsContentArea {
  display: flex;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin: 0 auto;
  min-height: 100%;
  height: 100%;
  max-width: 1020px;
  gap: 7rem;
  @media screen and (max-width: 768px) {
    grid-gap: 3rem;
  }

  @media screen and (max-width: 1040px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.productCardsContainer {
  flex: 4;
  -ms-flex: 4;
  justify-content: flex-start;
  @media screen and (max-width: 1040px) {
    order: 1;
  }
}

.productCardsInfoContainer {
  flex: 2;
  -ms-flex: 2;
  justify-self: flex-start;
  align-self: center;
  max-width: 500px;

  @media screen and (max-width: 1040px) {
    order: 1;
    margin: 0 auto;
    text-align: center;
  }
}

.productCardsInfoTitle {
  font-size: 4rem;
  font-family: $primary-header-font;
  margin-bottom: 3rem;
  color: $primary-title-color;

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

.productCardsInfoContent {
  font-size: 2rem;
  font-family: $primary-content-font;
  color: $primary-font-color;
  margin-bottom: 3rem;
  font-weight: 300;
}

.productCardsInfoButtonContainer {
}

.productCardsInfoButton {
  background-color: $primary-button-color !important;
  color: white !important;
  font-size: 2rem !important;
  text-transform: none !important;
  padding: 0.5rem 1.2rem !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
  transition: all 0.3s ease !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  backface-visibility: hidden !important;
}

.productCardsInfoButton:hover {
  transform: scale(1.1);
}

// .damageAssistanceContainer {
//   min-height: 400px;
//   display: grid;
//   grid-template-rows: 1fr 1fr;
//   grid-gap: 3rem;
//   position: relative;
//   background-color: $primary-color;

//   @media screen and (max-width: 768px) {
//     min-height: 290px;
//     grid-gap: 2rem;
//   }

//   @media screen and (max-width: 919px) {
//     grid-template-rows: 0fr 1fr;
//   }
// }

.damageAssistanceContainer {
  min-height: 400px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  position: relative;
  background-color: $primary-color;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    min-height: 290px;
    grid-gap: 2rem;
  }

  @media screen and (max-width: 919px) {
    grid-template-rows: 0fr 1fr;
  }
}

.damagaeAssistanceTitleContainer {
  align-self: center;
  font-size: 4rem;
  font-family: $primary-header-font;
  color: $primary-font-color-dk-bg;
  font-weight: 300;
}

.damagaeAssistanceContentContainer {
  align-self: center;
  font-family: $primary-content-font;
  color: $primary-font-color-dk-bg;
  font-size: 2rem;
  max-width: 1000px;
  padding: 0 2rem;
  font-weight: 300;
  text-align: center;
  padding: 3rem 0;
}

.damageAssistanceGuideButtonContainer {
  text-align: center;
}

.guideButton {
  font-size: 2rem !important;
  background-color: $primary-button-color !important;
  color: white !important;
  padding: 0.2rem 2.5rem !important;
  text-transform: none !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
  transition: all 0.3s ease !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  backface-visibility: hidden !important;
}

.guideButton:hover {
  transform: scale(1.1);
}

.contractedInstitutionsMainContainer {
  background-color: white;

  @media screen and (max-width: 768px) {
    padding: 4rem 0;
  }
}

.servicesContainer {
  flex: 5;
  -ms-flex: 5;
  display: flex;
  display: -ms-flex;
  flex-direction: row;
  -ms-flex-direction: row;
  width: 100%;
  height: 450px;
  justify-content: space-evenly;

  @media screen and (max-width: 1439px) {
    padding: 3rem 0;
  }

  @media screen and (max-width: 919px) {
    // flex-direction: column;
    padding: 2rem;
  }

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem 2rem;
  }
}

.contractedInstitutionsContainer {
  max-width: 1500px;
  display: flex;
  display: -ms-flexbox;
  padding: 5rem;
  margin: 0 auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    -ms-flex-direction: column;
    padding: 7rem;
  }

  @media screen and (max-width: 1023px) {
    padding: 1rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0rem;
  }
}

.contractedInstitutionsInfoContainer {
  flex: 3;
  -ms-flex: 3;
  justify-self: center;
  align-self: center;
  border-right: 2px solid #162a3b;
  max-width: 525px;
  padding: 0 6rem;

  @media screen and (max-width: 1439px) {
    align-self: center;
    text-align: center;
    border: none;
    padding: 3rem 0;
  }

  @media screen and (max-width: 1023px) {
    margin: 50px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
}

.contractedInstitutionsInfoTitle {
  font-size: 4rem;
  font-family: $primary-header-font;
  color: $primary-title-color;
  margin-bottom: 3rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
}

.contractedInstitutionsInfoContent {
  font-family: $primary-content-font;
  color: $primary-font-color;
  font-size: 2rem;
  margin-bottom: 3rem;
  font-weight: 300;
}

.contractedInstitutionsButton {
  background-color: $primary-button-color !important;
  color: white !important;
  font-size: 2rem !important;
  text-transform: none !important;
  padding: 0.5rem 1.2rem !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
  transition: all 0.3s ease !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  backface-visibility: hidden !important;
}

.contractedInstitutionsButton:hover {
  transform: scale(1.1);
}

.contractedServicesContainer {
  justify-self: center;
  align-self: center;
  height: 350px;
  width: 350px;
  border-radius: 0.5rem;
  cursor: pointer;
  margin: 0 5rem;
  transition: all 0.3s ease-in-out;

  box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);

  @media screen and (max-width: 1439px) {
    padding: 0;
  }

  @media screen and (max-width: 1023px) {
    height: 200px;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
    -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
    -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  }

  @media screen and (max-width: 600px) {
    height: 350px;
  }
}

.contractedServicesContainer:hover {
  box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
}

.healthInstitutionsContainer {
  justify-self: center;
  align-self: center;
  height: 350px;
  width: 350px;
  border-radius: 0.5rem;
  cursor: pointer;
  margin: 1rem 0;
  transition: all 0.3s ease-in-out;

  box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);

  @media screen and (max-width: 1023px) {
    height: 200px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
    -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
    -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  }

  @media screen and (max-width: 600px) {
    height: 350px;
  }
}

.healthInstitutionsContainer:hover {
  box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
}

.contractedLogoContainer {
  justify-self: center;
  align-self: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  border: 2px solid white;
}

.contractedTitleContainer {
  background-color: rgba($primary-color, 0.6);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-family: $primary-header-font;
  color: $primary-font-color-dk-bg;
  font-weight: 400;
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
}

.contractedContentContainer {
  justify-self: center;
  align-self: flex-start;
  font-family: $primary-content-font;
  color: $primary-font-color;
  font-size: 1.5rem;
  padding: 0 3rem;
  font-weight: 300;
}

.damageLink {
  text-decoration: none;
}

.tooltip {
  font-size: 1.5rem !important;
}

.mainButtonContainer {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  max-width: 220px;
  transition: all 0.3s ease-in-out;
  border: 1px solid $primary-divider-color;
  padding: 0 1rem;
  border-radius: 0.4rem;
  @media screen and (max-width: 1040px) {
    margin: 0 auto;
  }
}

.mainButtonContainer:hover {
  max-width: 240px;
}

.buttonTextContainer {
  font-size: 2rem !important;
  text-transform: none !important;
  padding: 0.5rem 1.2rem !important;
  font-family: $primary-header-font !important;
  font-weight: 500 !important;
  color: $primary-divider-color;
  // text-decoration: underline;
  transition: all 0.3s ease !important;
}

.buttonIconContainer {
  display: flex;
  align-items: center;
}

.buttonIcon {
  font-size: 2.5rem !important;
  color: $primary-divider-color !important;
}

.contractedInstButtonContainer {
  @media screen and (max-width: 1439px) {
    margin: 0 auto;
  }
}
