@import "../../../../../style.scss";

.container {
  max-width: 500px;
  margin: $primary-product-form-container-margin;
  padding: $primary-product-form-container-padding;
  display: flex;
  justify-content: center;
}

.backContainer {
  width: 100px;
}

.iconButton {
  padding: 0 !important;
}

.backIcon {
  font-size: 4rem !important;
  cursor: pointer;
  color: $primary-product-form-color;
}

.formContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.titleContainer {
  font-family: $primary-header-font;
  font-size: 2.5rem;
  font-weight: bold;
  color: $primary-title-color;
  margin-bottom: 2rem;
}

.inputContainer {
  margin-bottom: 2.5rem;
}

.plateContainer {
  display: flex;
}

.plateCodeContainer {
  width: 25%;
  margin-right: 3rem;
}

.plateTextContainer {
  width: 75%;
  display: flex;
  align-items: flex-end;
}

.label {
  font-family: $primary-content-font;
  font-size: $primary-product-form-label-font-size;
  color: $primary-product-form-color;
  margin-bottom: 0.5rem;
}

.textField {
  width: 100% !important;
  font-size: $primary-product-form-label-font-size !important;
}

.select {
  width: 100% !important;
  font-size: $primary-product-form-label-font-size !important;
}

.menuItem {
  font-size: $primary-product-form-label-font-size !important;
}

.reCaptchaContainer {
  margin-bottom: 2rem;
}

.kvkkLinkContainer {
  margin-bottom: 3rem;
  font-size: $primary-product-form-kvkk-font-size;
}

.kvkkLink {
  color: $primary-product-form-kvkk-color;
}

.buttonContainer {
  margin: 0 auto;
  max-width: 200px;
  margin-left: 5rem;
}

.proposalButton {
  width: 100% !important;
  color: $primary-product-form-color !important;
  font-size: $primary-product-form-label-font-size !important;
  text-transform: none !important;
  padding: 0.5rem 1.5rem !important;
  font-weight: 500 !important;
  border: 2px solid $primary-font-color-dk-bg !important;
}

.proposalButton:hover {
  background-color: $primary-product-form-color !important;
  color: $primary-divider-color !important;
}

.proposalButton:disabled {
  background-color: gray !important;
  pointer-events: all !important;
  cursor: not-allowed !important;
}

.registryNoFirstRow {
  display: flex;
}

.infoIcon {
  font-size: 2rem !important;
  cursor: pointer !important;
  color: $primary-divider-color !important;
}
