@import "../../../../style.scss";

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: center;
  padding: 0 4rem;
}

.productContainer {
  margin: 1rem;
  width: 250px;
  height: 250px;
  border-radius: 0.5rem;
  display: grid;
  grid-template-rows: 6fr 1fr;
  justify-self: center;
  cursor: pointer;
  background-color: white;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  transition: all 0.4s ease-in-out;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
}

.productContainer:hover {
  .productImgContainer {
    background-color: rgba($primary-color, 0.5);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .productNameContainer {
    transform: translateY(-215px);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    flex-direction: column;
  }

  .name {
    transform: translate(0, -250%);
  }

  .infoContainer {
    transform: translate(0, 250%);
  }
}

.productImgContainer {
  height: 215px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.productNameContainer {
  width: 250px;
  height: 250px;
  background: linear-gradient(to bottom, $primary-color, $primary-button-color);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 1.7rem;
  font-family: $primary-header-font;
  color: $primary-font-color-dk-bg;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 300;
  -moz-transition: height 0.4s;
  -ms-transition: height 0.4s;
  -o-transition: height 0.4s;
  -webkit-transition: height 0.4s;
  transform: translateX(0);
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.link {
  text-decoration: none;
  max-width: 350px;
  max-height: 350px;
  justify-self: center;
  // box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.11);
  transition: all 0.5s;
  border-radius: 0.5rem;
}

.link:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.otherContainer {
  margin: 1rem;
  width: 250px;
  max-height: 250px;
  border-radius: 0.5rem;
  justify-self: center;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  transition: all 0.4s ease-in-out;
}

.otherContainer:hover {
  background: #bcf5ff;
}

.otherMainContainer {
  cursor: pointer;
}

.otherLogoContainer {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 215px;
}
.otherLogo {
  color: $primary-title-color !important;
  font-size: 14rem !important;
  transition: all 0.4s ease !important;
}

.otherNameContainer {
  height: 35px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 1.5rem;
  font-family: $primary-header-font;
  background-color: $primary-color;
  color: $primary-font-color-dk-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
}

.otherAccordionNameContainer {
  font-size: 1.7rem;
  font-weight: 300;
  font-family: $primary-header-font;
  color: $primary-font-color-lg-bg;
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.otherAccordionNameContainer:hover {
  text-decoration: underline;
  cursor: pointer;
}

//accordion
.accordionContent {
  max-width: 75%;
  margin: 2rem auto;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.otherContainer[aria-expanded="true"] {
  max-height: none;
  // box-shadow: none;
  .accordionContent {
    opacity: 1;
    max-height: none;
    transition: all 200ms linear;
    will-change: opacity, max-height;
  }
}

.otherNameContainer[aria-expanded="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.hoverContent {
  opacity: 0;
  display: none;
  height: 250px;
  padding: 2rem;
  background: linear-gradient(to bottom, $primary-color, $primary-button-color);
  border-radius: 0.5rem;
  cursor: pointer;
}

.otherProductsNameContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.otherProductsNameContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.otherProductsNameContainer::-webkit-scrollbar {
  width: 10px;
}

.otherAccordionNameContainer {
  color: $primary-font-color-dk-bg;
}

.otherContainer[aria-expanded="true"] {
  .hoverContent {
    opacity: 1;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    overflow: hidden;
  }

  .otherMainContainer {
    display: none;
  }
}

.otherLink {
  text-decoration: none;
  text-align: left;
  width: 100%;
  font-size: 1.5rem;
}

.infoContainer {
  transition: all 0.4s ease-in-out;
  margin: 1rem 0;
}

.name {
  transition: all 0.4s ease-in-out;
  margin: 0.5rem 0;
}

.backCardTitleContainer {
  flex: 2;
  -ms-flex: 2;
  width: 100%;
  font-family: $primary-header-font;
  font-size: 2rem;
  color: $primary-font-color-dk-bg;
  text-align: left;
  align-self: center;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.backCardIcon {
  margin-right: 1rem;
  font-size: 2rem;
  cursor: pointer;
}

.otherProductsNameContainer {
  flex: 6;
  -ms-flex: 6;
  overflow: auto;
}
