@import "../../../style.scss";

.container {
  height: 100vh;
  margin-top: -9rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  background-position: center;
  background-size: cover;
  zoom: 127.7%;
}

@media (max-width: 650px) {
  .container {
    height: 100%;
  }
}

.contentContainer {
  position: relative;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 1rem;
  background: $primary-color;
  overflow: hidden;
  border-radius: 0.5rem;
}
.loader::before {
  content: "";
  position: absolute;
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%;
  border-radius: 0.5rem;
  background: $primary-divider-color;
  animation: animate 1.5s infinite linear;
}
@keyframes animate {
  0% {
    left: -60px;
  }
  100% {
    left: 300px;
  }
}

.description {
  color: $primary-title-color;
  font-family: $primary-content-font;
  font-size: 1.7rem;
  top: 50%;
  left: 50%;
  transform: translate(0%, 100%);
}

@media (max-width: 650px) {
  .loader {
    width: 300px;
  }
  .loader::before {
    width: 60px;
  }
  .description {
    text-align: center;
  }
}

.backButton {
  text-transform: none !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
  background: $primary-button-color !important;
  color: white !important;
  width: 300px !important;
  font-size: 1.7rem !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  backface-visibility: hidden !important;
  position: relative !important;
  display: inline-block !important;
}

.firstContainer {
  height: 100%;
  background-position: center;
  background-size: cover;
}

.secondContainer {
  height: 500px;
  width: 400px;
  border: 2px solid white;
  background: rgba(0, 0, 0, 0.288);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(0, 4, 56, 0.37);
  // backdrop-filter: blur(4px);
  // -webkit-backdrop-filter: blur(4px);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

@media (max-width: 850px) {
  .secondContainer {
    height: 100vh;
    border-radius: 0;
    width: 100%;
  }
}

.descriptionContainer {
  font-family: $primary-content-font !important;
  font-weight: 400 !important;
  font-size: 1.7rem;
  color: $primary-title-color;
  text-align: center;
  max-width: 300px;
}

.logoContainer {
  height: 75px;
  width: 200px;
}

.logo {
  height: 100%;
  background-position: center;
  background-size: cover;
}

.kvkkLinkContainer {
  font-size: 1.3rem;
  max-width: 308px;
}

@media (max-height: 450px) {
  .secondContainer {
    gap: 1rem;
  }
}
