@import "../../../../style.scss";

.container {
  color: #f5f5f5;

  .childProductsWrapper {
    max-width: 1440px;
    padding: 10rem 1rem;
    margin: 0 auto;
    color: #f5f5f5;

    @media screen and (max-width: 768px) {
      padding: 4rem 1rem;
    }

    .childProductsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media screen and (max-width: 1024px) {
        justify-content: space-around;
      }

      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      .childProducts {
        display: flex;
        width: 30%;
        flex-direction: column;
        max-width: 60rem;
        max-height: 30rem;
        overflow: auto;
        direction: rtl;

        @media screen and (max-width: 1024px) {
          width: 50%;
        }

        @media screen and (max-width: 768px) {
          flex: 1;
        }

        .childProductsTitle {
          width: 90%;
          direction: ltr;
          font-size: 2.2rem;
          line-height: 1;
          font-weight: 400;
          margin: 1rem;
          padding: 0.3rem;
          color: #3b3b3b;
          font-family: "Poppins", sans-serif !important;
          transition: transform 0.3s ease-in-out;
        }

        .childProductsTitle:hover {
          cursor: pointer;
          transform: translateX(3%);
        }
      }
      .imageWrapper {
        max-height: 30rem;
        min-height: 25rem;
        width: 30%;
        display: flex;

        @media screen and (max-width: 1024px) {
          width: 35%;
          min-height: 20rem;
        }

        @media screen and (max-width: 768px) {
          flex: 1;
          margin-top: 2rem;
        }

        .imgContainer {
          width: 100%;
          height: 100%;
          justify-self: start;
          align-self: center;
          border-radius: 0.5rem;

          .img {
            border-radius: 0.5rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .childProductsContentD {
        display: flex;
        width: 30%;
        color: #3b3b3b;
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
        padding-bottom: 4rem;

        .contentTitle {
          position: relative;
          font-size: 1.8rem;
          margin-bottom: 3rem;
        }

        .articleContainer {
          font-size: 1.8rem;
          margin-bottom: 3rem;
          max-height: 250px;
          overflow: auto;
        }

        .linkContainer {
          display: flex;
          align-items: flex-end;

          .detailedInfoLink {
            color: $primary-title-color;
            font-weight: 400;
            font-size: 1.5rem;
          }

          .linkIcon {
            font-size: 3rem !important;
            margin-left: 5px !important;
            color: $primary-title-color !important;
            padding-top: 1rem !important;
          }
        }
      }
    }

    .spaceNormal {
      justify-content: normal !important;
    }

    .childProductsContentM {
      display: flex;
      color: #3b3b3b;
      flex-direction: column;
      justify-content: space-between;
      align-self: center;
      padding: 3rem 0 2rem;

      .contentTitle {
        position: relative;
        font-size: 1.8rem;
        margin-bottom: 3rem;
        padding-top: 0.5rem;
      }

      .articleContainer {
        font-size: 1.8rem;
        margin-bottom: 3rem;
        max-height: 250px;
        overflow: auto;
      }

      .linkContainer {
        display: flex;
        align-items: flex-end;
        padding-bottom: 4rem;
        border-bottom: 1px solid #bdbcb7;

        .detailedInfoLink {
          color: $primary-title-color;
          font-weight: 400;
          font-size: 1.5rem;
        }

        .linkIcon {
          font-size: 3rem !important;
          margin-left: 5px !important;
          color: $primary-title-color !important;
          padding-top: 1rem !important;
        }
      }
    }
  }
}

.childProducts::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.childProducts::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.childProducts::-webkit-scrollbar {
  width: 10px;
}

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.childProductsMainContainer {
  max-width: 500px;
  margin-top: 3rem;
}

.accordionNameContainer {
  font-size: 1.7rem;
  color: $primary-title-color;
  font-family: $primary-header-font;
  font-weight: 300;
}

.accordionDetailsContainer {
  font-size: 1.5rem;
  color: $primary-font-color;
  font-family: $primary-content-font;
  width: 100%;
  font-weight: 300;
}

.accordionDetailsContentContainerr {
  max-width: 350px;
}

.accordionDetailsButtonsContainer {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.accordionLinkContainer {
  color: $primary-phone-color;
  align-self: center;
}

.accordionButton {
  background-color: $primary-button-color !important;
  color: white !important;
  font-size: 1.5rem !important;
  text-transform: none !important;
  padding: 0.5rem 1.2rem !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 3rem;
  color: $primary-title-color;
  font-family: $primary-header-font;
  margin-bottom: 4rem;
  line-height: 1;

  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    line-height: unset;
  }
}

.infoContainer {
  justify-self: end;
  align-self: flex-start;
}

.infoMainContainer {
  max-width: 500px;
}

.imageContainer {
  background-size: cover;
  background-position: center;
  height: 200px;
  background-color: $primary-color-dark-1;
  margin-bottom: 4rem;
  border-radius: 0.5rem;
}

.infoTitleContainer {
  font-size: 2rem;
  color: $primary-title-color;
  font-family: $primary-header-font;
  margin-bottom: 4rem;
}

.infoContentContainer {
  font-size: 1.5rem;
  color: $primary-font-color;
  font-family: $primary-content-font;
  width: 100%;
  margin-bottom: 4rem;
  font-weight: 300;
}

.infoButton {
  width: 100%;
  background-color: $primary-button-color !important;
  color: white !important;
  font-size: 1.5rem !important;
  text-transform: none !important;
  padding: 0.5rem 1.2rem !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
}

.productTitleLink {
  display: flex;
  align-items: center;
}

.productTitle:hover {
  cursor: pointer;
}

.productTitle::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: $primary-divider-color;
  transition: width 0.3s;
}

.productTitle:hover::after {
  width: 85%;
}

.menuItem {
  font-size: $primary-product-form-label-font-size !important;
}

.width {
  width: calc(((100% - 15rem) / 10 * 3 + 3rem));
}

.noImageWidth {
  width: calc(((100% - 15rem) / 8 * 3 + 3rem));
}

.borderTop {
  border-top: 1px solid #bdbcb7;
}

.marginRight {
  margin-right: 5px;
}

.margin {
  margin-right: 5px;
  margin-bottom: 2px;
}
