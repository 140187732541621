$bg: white;

.alertContainer {
  position: fixed;
  top: 10px;
  right: 0px;
  max-width: 700px;
}

.snackbar {
  position: static !important;
  position: fixed;
  padding: 0 20px 5px 0;
  min-width: 250px;
  text-align: center;
}

.divider {
  position: relative;
  margin-bottom: 0em;
  padding: 0.7em 0;
  height: 1px;
  &:before {
    content: "";
    position: absolute;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-color: aliceblue;
  }
}

@media screen and (max-width: 700px) {
  .alertContainer {
    position: fixed;
  }
}
