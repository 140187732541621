@import "../../../../style.scss";

.container {
  max-width: 600px;
  margin: 0 auto;
}

.titleContainer {
  color: $primary-contact-form-color;
  font-family: $primary-header-font;
  text-align: center;
}

.title {
  font-size: 3.5rem;
  padding: 1rem 0;
  color: $primary-title-color;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.content {
  font-size: 2rem;
  font-weight: 300;
  padding: 1rem 0;
  color: $primary-contact-form-color;
  font-family: $primary-content-font;
}

.formContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.inputContainer {
  margin: 2rem 2rem;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin: 1rem 2rem;
  }
}

.label {
  text-align: left;
  padding: 1rem;
  font-weight: 300;
  font-size: 1.8rem;
  color: $primary-contact-form-color;
  font-family: $primary-content-font;
}

.kvkkContainer {
  margin: 2rem 2rem;
  font-size: 1.5rem;
  font-weight: 300;
  color: #2792d6;
  text-decoration: underline;
  cursor: pointer;
}

.commercialContainer {
  display: flex;
  margin: 2rem 2rem;
  text-align: left;
}

.commercialCheckbox {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(1.5);
  margin-top: 1rem;
}

.commercialContentContainer {
  font-size: 1.5rem;
  font-weight: 300;
  text-align: left;
  color: $primary-contact-form-color;
  font-family: $primary-content-font !important;
}

.sendBtn {
  color: white !important;
  background-color: $primary-button-color !important;
  font-size: 2rem !important;
  padding: 0.7rem 0 !important;
  box-shadow: none !important;
  width: 100% !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
  transition: all 0.3s ease !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  backface-visibility: hidden !important;
}

.sendBtn:hover {
  transform: scale(1.1);
}

.sendBtn:disabled {
  // background-color: gray !important;
  transform: scale(1);
  pointer-events: all !important;
  cursor: not-allowed !important;
}

.MuiInputBase-root {
  border-radius: 10px !important;
}

.sendBtnContainer {
  margin: 2rem auto;
  width: 40%;
}

.kvkkLink {
  color: $primary-contact-form-color;
  font-family: $primary-content-font !important;
}

.formHelper {
  // margin-top: -1rem !important;
}

.select {
  width: 100% !important;
  font-size: 1.5rem !important;
}

.menuItem {
  font-size: 1.5rem !important;
}

.detail {
  color: $primary-contact-form-color !important;
  font-family: $primary-content-font !important;
  font-size: 2rem !important;
}

.selectLabel {
  font-size: 1.8rem !important;
  color: #9f9f9f !important;
  padding-left: 6px !important;
}

.rowContainer {
  display: flex;
  flex-wrap: wrap;
}

.commercialFormControl {
  display: inline-block !important;
  margin-right: 1rem;
}

.captchaContainer {
  margin: 2rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  div > div > div {
    margin: 0 auto;
  }
}

.captchaImageContainer {
  display: flex;
  align-items: center;
  flex: 1 1 0px;
}

.captchaTextInput {
  flex: 1 1 0px;
}

@media screen and (max-width: 600px) {
  .captchaContainer {
    display: block;
  }
  .captchaContainer > div {
    display: flex;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .captchaContainer {
    margin: 2rem 0;
  }
}

.renewIcon {
  font-size: 3rem !important;
  cursor: pointer !important;
}
