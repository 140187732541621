@import "../../../../style.scss";

.carouselContainer {
  position: relative;
  // max-width: 1500px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  z-index: 1;

  .mainContainer {
    height: 300px;
    scroll-snap-align: center;
    padding: 2rem;
  }

  .container {
    height: 100%;
    width: 100%;
    justify-self: center;
    align-self: center;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    border-radius: 0.5rem;
    box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
    -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
    -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .imgContainer {
    flex: 4;
    -ms-flex: 4;
    width: 100%;
    height: 100%;
    justify-self: center;
    align-self: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-size: cover;
    background-position: center;
  }

  .titleContainer {
    flex: 1;
    -ms-flex: 1;
    width: 100%;
    height: 100%;
    justify-self: center;
    align-self: center;
    background-color: rgba($primary-color, 0.3);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    font-size: 1.7rem;
    font-family: $primary-header-font;
    color: $primary-font-color-dk-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }
}

.content {
  margin: 1rem 0;
  color: white;
  font-size: 2.2rem;
  font-family: $primary-content-font;
  font-weight: 300;

  @media (max-width: 728px) {
    font-size: 1.8rem;
  }
}

.photoContainer {
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
  display: grid;
  grid-template-rows: 4fr 1fr;
  border-radius: 0.5rem;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.photoContent {
  opacity: 0;
  height: 100%;
  font-size: 1.5rem;
  background-color: rgba($primary-color, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-font-color-dk-bg;
  font-family: $primary-header-font;
  transition: all 0.5s ease-in-out;
  padding: 1rem;
}

.photoContainer:hover {
  .photoContent {
    opacity: 1 !important;
  }
}

.container:hover {
  .photoContent {
    opacity: 1 !important;
  }
}
