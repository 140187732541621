@import "../../../style.scss";

.margin {
  margin: 0 10% 0 35%;
}

.container {
  height: 100vh;
  padding: 4rem;
  margin-top: -90px;
  display: flex;
  flex-direction: column;
  background-color: $primary-bg-color;
  zoom: 127.7%;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  .containerBackground {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-image: url("../../../components/assets/images/not_found.png");
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.7;
    background-color: $primary-bg-color;

    @media screen and (max-width: 768px) {
      height: 70%;
    }
  }

  .messageContainer {
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 10rem;
      margin-bottom: 2rem;
      font-weight: 300;
      font-family: $primary-content-font;
      text-align: center;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;

      @media screen and (max-width: 768px) {
        font-size: 7rem;
      }
    }

    .contentContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 1264px) {
        flex-direction: column;
        margin: 0;
      }

      .content {
        flex: 1.3;
        font-size: 2rem;
        font-weight: 300;
        font-family: $primary-content-font;
        text-align: center;

        @media screen and (max-width: 1264px) {
          padding-bottom: 5rem;
        }
      }

      .buttonWrapper {
        flex: 1;
        width: 100%;

        @media screen and (max-width: 1264px) {
          flex: none;
          width: auto;
        }

        .mainButtonContainer {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          max-width: 240px;
          transition: all 0.3s ease-in-out;
          border: 1px solid $primary-divider-color;
          padding: 0 1rem;
          border-radius: 0.4rem;
          text-decoration: none;
          @media screen and (max-width: 1040px) {
            margin: 0 auto;
          }

          .buttonTextContainer {
            font-size: 2rem;
            text-transform: none !important;
            padding: 0.5rem 1.2rem !important;
            font-family: $primary-header-font !important;
            font-weight: 500 !important;
            color: $primary-divider-color;
            transition: all 0.3s ease !important;
          }

          .buttonIconContainer {
            display: flex;
            align-items: center;

            .buttonIcon {
              font-size: 2.5rem !important;
              color: $primary-divider-color !important;
            }
          }
        }

        .mainButtonContainer:hover {
          max-width: 260px;
        }
      }
    }
  }
}
