@import "../../../style.scss";

.slick_dot {
  position: absolute;
  padding: 0;
  border-radius: 40px;
  text-align: center;
  padding: 10px;
  left: calc(4%);
  bottom: 50%;

  @media (max-width: 1040px) {
    display: flex !important;
    justify-content: center;
    flex-direction: row;
    vertical-align: middle;
    margin: auto;
    position: initial;
    padding-bottom: 2rem;
  }
}

.slick_dot ul {
  display: flex;
  flex-direction: row;
}

.slick_dot li {
  list-style: none;
  display: flex;
  margin: 1rem 5px;
  padding: 0;
}

.slick_dot li button {
  background: transparent;
  color: transparent;
  cursor: pointer;
  display: flex;
  height: 10px;
  width: 10px;
  border: 0.0625rem solid white;
  border-radius: 7.5px;
  transition: all 0.3s ease-in-out;
}

.slick_dot li.slick-active button {
  background-color: white;
  transform: scale(1.4);
}

.slick_dot li button:hover {
  outline: 0;
  transform: scale(1.4);
}

.slick_dot li button:focus {
  outline: 0;
}

.slick-slider {
  height: 100%;
}
