@import "../../../style.scss";

$title-color: #fefefe !important;

.container {
  background-color: $primary-bg-color;
  border-top: 1px solid #162a3b30;
  padding-bottom: 0;
}

.contactMainTitleContainer {
  font-family: $primary-header-font;
  font-size: 4rem;
  color: $primary-title-color;
  text-align: center;
  margin-top: 3rem;
}

.mainContainer {
}

.firstMainContainer {
  background: $primary-color;
  padding: 7rem 1rem;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 5rem 1rem;
  }
}

.firstContainer {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  order: 3;
  padding: 2rem 4rem;
  border-radius: 1rem;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  padding-bottom: 5rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 0;
  }
}

.secondMainContainer {
  background-color: $primary-second-footer-bg;
}

.secondContainer {
  display: flex;
  justify-content: center;
  max-width: 1500px;
  margin-bottom: 0;
  padding: 2rem 0;
}

.mapContainer {
  width: 550px;
  margin: 0 2rem;
}

.contactContainer {
  margin: 0 2rem;
  height: 275px;
  width: 550px;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  flex-direction: column;
  -ms-flex-direction: column;
  border-radius: 0.5rem;
  color: $primary-contact-form-color;
  font-size: 2.5rem;
  text-align: left;
  padding: 2rem;
  background-color: $primary-color;
  box-shadow: -1px 0px 30px 0.5px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: -1px 0px 30px 0.5px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: -1px 0px 30px 0.5px rgba(255, 255, 255, 0.2);
  border: 2px solid white;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}

.contactTitle {
  font-size: 3rem;
  font-weight: 400;
  font-family: $primary-header-font;
  color: $primary-title-color;
  text-align: left;
  padding: 1rem 0;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.phoneContainer {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
  display: inline-block;
}

.mailContainer {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 300;
  text-align: center;
  position: relative;
  display: inline-block;
}

.contactContentContainer {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  font-weight: 300;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  color: white;
}

.socailsContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.socialIconContainer {
  font-size: 2rem !important;
  margin: 0 2px;
  cursor: pointer;
}

.socialIcon {
  font-size: 3rem !important;
  color: $primary-font-color-dk-bg !important;
  transition: all 0.2s ease-in-out !important;
}

.socialIcon:hover {
  transform: scale(1.5) !important;
}

.contactFormContainer {
  width: 100%;
  margin: 0 auto;
  order: 1;
  padding: 7rem 1rem 4rem;
  padding-top: 0;

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem 4rem;
  }

  @media screen and (max-width: 350px) {
    padding: 0;
  }
}

.contactFormTitle {
  padding: 2rem 2rem;
  font-size: 3rem;
  color: $primary-footer-color;
  font-family: $primary-header-font;
}

.sitemapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 2rem;
}

.sitemapTitle {
  font-size: 3rem;
  color: $primary-footer-color;
  font-family: $primary-header-font;
}

.sitemapItemTitleContainer {
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 500;
  color: $title-color;
  font-family: $primary-header-font !important;
}

.sitemapItem {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem !important;
  font-family: $primary-header-font !important;
}

.sitemapLink {
  text-decoration: none !important;
  color: $primary-footer-color !important;
}

.sitemapTitleLink {
  text-decoration: none !important;
  color: $title-color;
}

.sitemapLink:hover {
  text-decoration: underline !important;
}

.infoContainer {
  padding: 2rem;
}

.logosContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.7rem;
}

.logoChild {
  height: 5vh;
  position: relative;
  margin: 0 2rem;
}

.logo {
  object-fit: cover;
  height: 100%;
  cursor: pointer;
}

.infoContainerAgencyNameContainer {
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: 4rem;
  font-family: $primary-content-font !important;
}

.infoContainerContent {
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: 2rem;
  font-family: $primary-content-font !important;
}

.verticalLine {
  border-left: 2px solid white;
  height: 70px;
}

.socialLink {
  color: inherit;
  margin-right: 2rem;
}

.mail {
  cursor: pointer;
  color: $primary-font-color-dk-bg !important;
  font-size: 1.7rem !important;
  font-weight: 300 !important;
  text-transform: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.phone {
  cursor: pointer;
  color: $primary-font-color-dk-bg !important;
  font-size: 1.7rem !important;
  font-weight: 300 !important;
  font-family: $primary-content-font;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.registry {
  color: $primary-font-color-dk-bg !important;
  font-size: 1.7rem !important;
  font-weight: 300 !important;
  font-family: $primary-content-font;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  color: $primary-font-color-dk-bg !important;
  font-size: 1.7rem !important;
  font-weight: 300 !important;
  font-family: $primary-content-font;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.location {
  font-size: 2rem !important;
  margin-right: 1rem !important;
  margin-top: 0.5rem !important;
}

.sitemapMainContainer {
  margin: 2rem;
  align-self: flex-start;
}

.dividerContainer {
  order: 2;
  width: 100%;
  max-width: 2px;
  margin: 2rem;
  display: flex;
  align-items: center;
}

.divider {
  width: 2px;
  height: 350px;
  background-color: $primary-color;
}

.contactTitle {
  font-size: 3.5rem;
  text-align: center;
  font-family: $primary-header-font;
  color: $primary-font-color-dk-bg;
}

.arrowContainer {
  position: relative;
  width: 100%;
  height: 50px;
  background: $primary-color;
}

@media screen and (max-width: 1300px) {
  .firstContainer {
    justify-content: center;
  }
}

@media screen and (max-width: 1225px) {
  .secondContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .socailsContainer {
    justify-content: center;
  }
}

@media screen and (max-width: 574px) {
  .secondContainer {
    text-align: center;
  }
}

@media screen and (max-width: 1006px) {
  .mainContainer {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .dividerContainer {
    width: 100%;
    max-width: 350px;
    margin: 2rem;
    display: flex;
    justify-content: center;
    height: 2px;
  }

  .divider {
    width: 100%;
    height: 2px;
    background-color: $primary-color;
  }
}

@media screen and (max-width: 768px) {
  .mapContainer {
    width: 100%;
    margin: 0;
  }
  .contactContainer {
    width: 100%;
  }
}

.infoIcon {
  font-size: 2rem !important;
  margin-right: 1rem;
}

.tooltipText {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  background-color: #f5f5f5;
  color: rgb(1, 0, 34);
  text-align: center;
  border-radius: 2px;
  padding: 0.3rem 1rem;
  font-size: 1.5rem;
  margin-left: 1rem;
  top: -3px;
  font-weight: 500;
  font-family: $primary-header-font;
  border: 1px solid #dadde9;
}

.mailContainer:hover .tooltipText {
  visibility: visible;
}

.phoneContainer:hover .tooltipText {
  visibility: visible;
}
