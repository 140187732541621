@import "../../../style.scss";

.container {
  padding: 1rem;
  margin-bottom: 5rem;
}

.productsTitleContainer {
  font-family: $primary-header-font;
  font-size: 4rem;
  color: $primary-title-color;
  margin: 5rem auto;
  text-align: center;

  @media screen and (max-width: 728px) {
    margin: 5rem auto 2rem;
  }
}

.parentProductsContainer {
  margin: 4rem 0;

  @media screen and (max-width: 728px) {
    margin: 1rem 0;
  }
}

.combinedCardContainer {
  padding: 5% 0;
  width: 40%;
  margin: auto;
}

.combinedCardTitleContainer {
  font-size: 45px;
  font-weight: bold;
  color: #3b3b3b;
}

.combinedCardContentContainer {
  padding: 20px 0;
  font-size: 15px;
  color: #3b3b3b;
}

@media screen and (max-width: 1400px) {
  .combinedCardContainer {
    padding: 5% 0;
    width: 50%;
    margin: auto;
  }
}

@media screen and (max-width: 1200px) {
  .combinedCardContainer {
    padding: 5% 0;
    width: 70%;
    margin: auto;
  }
}

@media screen and (max-width: 900px) {
  .combinedCardContainer {
    padding: 5% 0;
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .combinedCardContainer {
    padding: 4% 0;
    width: 100%;
    margin: auto;
  }
}
