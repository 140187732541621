@import "../../../style.scss";

.container {
  padding: 7rem 0;
  background-color: $primary-bg-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 5rem 1rem 6rem;
    align-items: normal;
  }
}

.contractedMainTitleContainer {
  text-align: center;
  font-size: 4rem;
  font-family: $primary-header-font;
  color: $primary-title-color;
}

.mainContainer {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 7rem auto;
  margin-bottom: 3rem;

  @media screen and (max-width: 1039px) {
    margin: 0;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.contractedServicesContainer {
  margin: 1rem 5rem;
  justify-self: center;
  align-self: center;
  height: 400px;
  background-color: $primary-institutions-card-bg;
  width: 400px;
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 1039px) {
    margin: 0.5rem 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    height: 400px;
  }
}

.healthInstitutionsContainer {
  margin: 1rem 5rem;
  cursor: pointer;
  justify-self: center;
  align-self: center;
  height: 400px;
  background-color: $primary-institutions-card-bg;
  width: 400px;
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 1039px) {
    margin: 0.5rem 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    height: 400px;
  }
}

.contractedLogoContainer {
  justify-self: center;
  align-self: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  border: 2px solid white;
}

.contractedTitleContainer {
  background-color: rgba($primary-color, 0.6);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-family: $primary-header-font;
  color: $primary-font-color-dk-bg;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.contractedContentContainer {
  justify-self: center;
  align-self: flex-start;
  font-family: $primary-content-font;
  color: $primary-font-color;
  font-size: 1.5rem;
  padding: 0 3rem;
  font-weight: 300;
}

.vehicleIcon {
  font-size: 15rem !important;
  font-weight: 400 !important;
  // color: $primary-font-color-dk-bg !important;
  color: $primary-color !important;
}

.healthIcon {
  font-size: 15rem !important;
  font-weight: 400 !important;
  // color: $primary-font-color-dk-bg !important;
  color: $primary-color !important;
}
