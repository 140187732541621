@import "../../../style.scss";

.aboutMainContainer {
  background-image: linear-gradient(
    to top right,
    $primary-color-light-2,
    white
  );
  max-height: 500px;
  text-align: center;
  background-size: cover;
  background-position: center;
  box-shadow: -1px 0px 50px 50px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 50px 50px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 50px 50px rgba(89, 89, 89, 0.2);
  margin: 0 auto;
}

.aboutContainer {
  margin: 0 auto;
  position: relative;
  height: 100%;
  background-color: rgba($primary-color, 0.5);
  min-height: 415px;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.clip {
  position: absolute;
  top: 0;
  left: 0;
  float: right;
  min-height: 415px;
  width: 100%;
}

.aboutTitleContainer {
  font-family: $primary-header-font;
  font-size: 3rem;
  color: $primary-font-color-dk-bg;
}

.aboutSubTitleContainer {
  text-align: center;
  font-family: $primary-header-font;
  font-size: 3rem;
}

.aboutContentContainer {
  font-family: $primary-content-font;
  color: $primary-font-color-dk-bg !important;
  font-weight: normal;
  text-align: justify;
  padding: 7rem 2rem;
  background-color: rgba(rgb(192, 192, 192), 0.2);
  @media screen and (max-width: 728px) {
    padding: 5rem 2rem;
  }
}

.content {
  font-size: 1.7rem;
  font-family: $primary-content-font;
  font-weight: 300;
  max-width: 1000px;
  margin: 4rem auto;
  text-align: justify;
  @media screen and (max-width: 728px) {
    text-align: justify;
  }
}

.moreContent {
  max-height: none;
}

.aboutButtonContainer {
  margin: 1rem 0;
  text-align: center;
}

.aboutButton {
  font-size: 2rem !important;
  font-weight: bold;
  background-color: $primary-button-color !important;
  color: white !important;
  padding: 0.2rem 2.5rem !important;
  font-weight: 400 !important;
  text-transform: none !important;
  font-family: $primary-header-font !important;
  width: 250px;
  transition: all 0.3s ease !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2) !important;
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2) !important;
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2) !important;
}

.aboutButton:hover {
  transform: scale(1.1);
}

.photoContainer {
  margin: 5rem 2rem;

  @media screen and (max-width: 600px) {
    margin: 3rem 2rem;
  }
}

.photoContentContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
    padding: 0;
  }
}

.prizeContainer {
  margin: 0 2rem 5rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
  }
}

.prizeContentContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
    padding: 0;
  }
}

.prizeTitleContainer {
  font-family: $primary-header-font;
  font-size: 3rem;
  color: $primary-title-color;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5rem;
  max-width: 1000px;

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
  }
}
.prizeTitle:before,
.prizeTitle:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: "\a0";
  background-color: red;
}

.middle {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -72%) !important;
  text-align: center;
}

.dividerContainer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.divider {
  width: 100px;
  height: 2px;
  background-color: $primary-divider-color;
}

.marginTop {
  margin-top: 5rem;

  @media screen and (max-width: 600px) {
    margin-top: -1rem;
  }
}

.paddingTop {
  padding-top: 5rem;

  @media screen and (max-width: 600px) {
    padding-top: 3rem;
  }
}

.marginBottomv1 {
  margin-bottom: 5rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 2rem;
  }
}

.marginBottomv2 {
  margin-bottom: 2rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 0.5rem;
  }
}
