@import "../../../../style.scss";

.container {
  padding: 7rem 0 4rem;
  background-color: $primary-bg-color;

  @media screen and (max-width: 728px) {
    padding: 2rem 1rem 0rem;
  }
}

.mainContainer {
  max-width: 1500px;
  margin: 0 auto;
}

.titleContainer {
  font-size: 3rem;
  font-family: $primary-header-font;
  color: $primary-title-color;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 728px) {
    margin: 2rem auto 1rem;
  }
}

.servicesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 4rem auto;

  @media screen and (max-width: 728px) {
    margin: 0;
    flex-wrap: wrap;
  }
}

.contactedServicesMainContainer {
  height: 100%;
  margin: 0 2rem;

  @media screen and (max-width: 728px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.contractedServicesContainer {
  height: 350px;
  position: relative;
  justify-self: center;
  max-height: 350px;
  background-color: $primary-institutions-card-bg;
  width: 350px;
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  transition: all 0.3s ease-in-out;
  border-radius: 0.5rem;

  @media screen and (max-width: 728px) {
    width: 100%;
  }
}

.accordionMainContentContainer {
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
}

.contractedLogoContainer {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  // width: 250px;
  // height: 250px;
}

.contractedTitleContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  font-family: $primary-header-font;
  color: $primary-font-color-dk-bg;
  align-items: center;
  font-weight: 400;
  background-color: rgba($primary-color, 0.6);
  justify-self: center;
  align-self: center;
  --border-width: 5px;
  border-radius: var(5px);
  border: 2px solid white;
  border-radius: 0.5rem;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.contractedContentContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  font-family: $primary-content-font;
  color: $primary-font-color;
  font-size: 1.5rem;
  padding: 0 3rem;
  font-weight: 300;
}

.vehicleIcon {
  font-size: 15rem !important;
  font-weight: 400 !important;
  color: $primary-color !important;
}

.inputContainer {
  margin-top: 2rem;
}

.glassInfoContainer {
  margin-top: 2rem;
  color: $primary-font-color;
  text-align: center;
  font-size: 1.5rem;
}

.buttonContainer {
  margin: 0 auto;
  margin-top: 2rem;
}

.searchButton {
  width: 100% !important;
  background-color: $primary-button-color !important;
  color: white !important;
  font-size: 2rem !important;
  text-transform: none !important;
  padding: 0.5rem 1.2rem !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
}

.searchButton:disabled {
  background-color: gray !important;
}

.resultTitleContainer {
  text-align: center;
  font-size: 3rem;
  font-family: $primary-header-font;
  color: $primary-title-color;
  margin-bottom: 2rem;
}

.expandMoreContainer {
  text-align: center;
  margin: 2rem 0;
}

.expandMoreButton {
  font-size: 2rem !important;
  font-family: $primary-header-font !important;
  color: $primary-title-color !important;
  text-transform: none !important;
  padding: 1rem 2rem !important;
  font-weight: 400 !important;
}

.expandMoreButton:disabled {
  color: rgb(212, 212, 212) !important;
}

//accordion
.accordionContent {
  max-width: 75%;
  margin: 0 auto;
  margin-top: 2rem;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.contractedServicesContainer[aria-expanded="true"] {
  max-height: none;
  box-shadow: none;
  .accordionContent {
    opacity: 1;
    max-height: none;
    transition: all 200ms linear;
    will-change: opacity, max-height;
  }
}

.contractedServicesBack {
  margin: 3rem 0;
  color: $primary-font-color-dk-bg;
  font-family: $primary-header-font;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 728px) {
    margin: 1rem 0 2rem;
  }
}

.serviceButton {
  background-color: $primary-button-color !important;
  color: $primary-font-color-dk-bg !important;
  text-transform: none !important;
  font-family: $primary-header-font !important;
  font-size: 1.7rem !important;
  width: 100% !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2) !important;
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2) !important;
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2) !important;
  transition: all 0.3s ease-in-out !important;
  backface-visibility: hidden !important;
  height: 50px !important;
  @media screen and (max-width: 400px) {
    height: 100% !important;
  }
}

.serviceButton:hover {
  background-color: white !important;
  color: $primary-button-color !important;
  transform: scale(1.1) !important;
}

.serviceButton:active {
  transform: scale(1) !important;
}

.institutionsTitleLink {
  display: inline-block;
  line-height: 1;
}

.institutionsTitleLink:hover {
  cursor: pointer;
}

.institutionsTitleLink::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: $primary-divider-color;
  transition: width 0.3s;
}

.institutionsTitleLink:hover::after {
  width: 100%;
}
