@import "../../style.scss";

.brand {
  font-weight: 700;
  font-size: 20px;
}

.siteheader {
  position: relative;
  background: white;
  position: fixed;
  min-height: 90px;
  top: 0px;
  width: 100%;
  font-family: $primary-content-font !important;
  z-index: 1000;
  font-size: 1.8rem;
  box-shadow: 0px 0px 10px 0px $header-shadow;
  border-bottom: 1px solid hsla(0, 0%, 81%, 0.16);
  display: flex;
}

.siteheader__wrapper {
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1040px) {
  .siteheader__wrapper {
    justify-content: initial;
  }
}

@media (min-width: 1040px) {
  .siteheader__middle {
    margin-left: auto;
  }
}

@media (max-width: 1039px) {
  .siteheader__end {
    margin-left: auto;
  }
}

@media (min-width: 1040px) {
  .nav__wrapper {
    display: flex;
    list-style: none;
  }
}

.menuButtonContainer {
  margin-left: auto;
  padding: 0 15px;

  @media (max-width: 1039px) {
    margin-left: 0;
    padding: 0;
  }

  .menuIcon {
    color: $primary-font-color-lg-bg;
    font-size: 2.5rem !important;
  }
}

.listItem {
  padding: 0 !important;
}

.drawerLink {
  width: 100%;
  text-decoration: none;
  color: $primary-color;
  padding: 15px 18px;
  font-size: 1.8rem !important;
}

.divider {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.sidebarContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.socialsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 7px 0;

  a {
    margin: 0 7px;
    color: $primary-color;
  }
  a:hover {
    color: $primary-button-color;
  }
}

.nav__item {
  display: block;
  color: $primary-color;
  text-decoration: none;
  font-weight: bold;
}

.nav__item__link {
  display: block;
  padding: 0 1rem;
  color: $primary-color;
  text-decoration: none;
}

.nav__item__link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: $primary-divider-color;
  transition: width 0.3s;
}

.nav__item__link:hover::after {
  width: 100%;
}

.wrapper {
  max-width: 1400px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.logoChild {
  height: 6vh;
  position: relative;
  min-height: 5rem;
}

.logo {
  object-fit: cover;
  height: 100%;
  cursor: pointer;
}

.proposalButtonContainer {
  padding: 0 1rem;
}

.proposalButton {
  color: white !important;
  min-width: 100px !important;
  font-size: 1.7rem !important;
  background-size: 300% 100% !important;
  background-position: -100% 0%;
  background-image: linear-gradient(
    45deg,
    $primary-indicator-color-1,
    $primary-indicator-color-1,
    $primary-indicator-color-2,
    $primary-indicator-color-2
  ) !important;
  backface-visibility: hidden !important;
  position: relative !important;
  display: inline-block !important;
  font-family: $primary-header-font !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
  border-radius: 0.4rem !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

.proposalButton:hover {
  background-position: -145% 0% !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.socialIcon {
  font-size: 2.5rem !important;
}
