@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./style.scss";
@import "./components/helpers/tabs/sale-tab/saletab.scss";
@import "react-magic-slider-dots/dist/magic-dots.css";

.main-content {
  width: 100%;
  margin-top: 90px;
}

@media screen and (max-width: 700px) {
  .MuiBox-root-2 {
    display: block !important;
  }

  .MuiToolbar-root {
    display: block !important;
  }
}

.contactFormInputField {
  .MuiOutlinedInput-root {
    border-radius: 10px !important;
  }
}

.MuiTabs-flexContainer {
  display: inline !important;
}

.PrivateTabIndicator-root-10 {
  background-color: #2792d6 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  // color: #2792D6 !important;
  color: inherit !important;
}

.PrivateTabIndicator-root-10 {
  display: none !important;
}

.MuiTabs-scroller {
  white-space: normal !important;
}

.MuiTab-labelIcon {
  margin: 0 20px !important;
}

.MuiTab-root {
  overflow: inherit;
  margin: 0 20px !important;
  text-transform: none !important;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(1, 0, 46, 0.13);
}

.MuiInputBase-input {
  font-size: 1.5rem !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.MuiFormHelperText-root {
  font-size: 1.25rem !important;
}

.MuiSelect-select:focus {
  border-radius: 0;
}
.MuiSelect-select.MuiSelect-select {
  padding-left: 1rem;
}

.MuiSelect-icon {
  margin-right: 1rem !important;
}

.MuiMenu-paper {
  max-height: 400px !important;
}

.dateContainer {
  .MuiFormControl-root {
    width: 100% !important;
    display: block !important;
    .MuiFormLabel-root {
      font-size: 1.5rem !important;
    }
  }
  .MuiInputBase-root {
    width: 100% !important;
  }
}

.MuiPickersCalendarHeader-dayLabel {
  font-size: 1.5rem !important;
}

.MuiTypography-body2 {
  font-size: 1.5rem !important;
}

.MuiTypography-body1 {
  font-size: 1.5rem !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #2792d6 !important;
}

.MuiIconButton-colorSecondary {
  color: #2792d6 !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 63, 134, 0.04) !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.MuiInputBase-inputMultiline {
  line-height: 2rem !important;
}

.MuiListSubheader-sticky {
  position: relative !important;
  font-size: 1.5rem !important;
}

/* headlines with lines */
.decorated {
  overflow: hidden;
  text-align: center;
  font-size: 3rem;
}
.decorated > span {
  position: relative;
  display: inline-block;
  color: $primary-color;
  font-weight: 400;
}
.decorated > span:before,
.decorated > span:after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 2px solid;
  width: 100vw;
  margin: 0 20px;
  color: $primary-color;
}
.decorated > span:before {
  right: 100%;
  color: $primary-color !important;
}
.decorated > span:after {
  left: 100%;
  color: $primary-color !important;
}

.decoratedNoSeparator {
  overflow: hidden;
  text-align: center;
  font-size: 3rem;
}

.decoratedNoSeparator > span {
  position: relative;
  display: inline-block;
  color: $primary-color;
  font-weight: 400;
}

.MuiFormLabel-root {
  color: black !important;
}

.custom-shape-divider-bottom-1618394262 {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1618394262 svg {
  position: relative;
  display: block;
  width: calc(185% + 1.3px);
  height: 110px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1618394262 .shape-fill {
  fill: #ffffff;
}

.custom-shape-divider-bottom-1618394262 .shape-fill-sale {
  fill: $primary-bg-color;
}

.custom-shape-divider-top-1618394393 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1618394393 svg {
  position: relative;
  display: block;
  width: calc(185% + 1.3px);
  height: 110px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1618394393 .shape-fill {
  fill: #f5f5f5;
}

@media (max-width: 1040px) {
  .custom-shape-divider-bottom-1618394262 {
    display: none;
  }
}

.custom-shape-divider-bottom-1618399642 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1618399642 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 120px;
}

.custom-shape-divider-bottom-1618399642 .shape-fill {
  fill: $primary-bg-color;
}

.custom-shape-divider-bottom-1618464263 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1618464263 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 50px;
}

.custom-shape-divider-bottom-1618464263 .shape-fill {
  fill: $primary-bg-color;
}

@media screen and (max-height: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.custom-shape-divider-top-1619264815 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1619264815 svg {
  position: relative;
  display: block;
  width: calc(185% + 1.3px);
  height: 110px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1619264815 .shape-fill {
  fill: #ffffff;
}

.custom-shape-divider-bottom-1619415066 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1619415066 svg {
  position: relative;
  display: block;
  width: calc(124% + 1.3px);
  height: 155px;
}

.custom-shape-divider-bottom-1619415066 .shape-fill {
  fill: #ffffff;
}

.proposalButton {
  color: white !important;
  min-width: 100px !important;
  font-size: 2rem !important;
  display: inline-block !important;
  font-family: $primary-header-font !important;
  padding: 0.5rem 2rem !important;
  background-size: 300% 100% !important;
  background-position: -100% 0%;
  background-image: linear-gradient(
    45deg,
    $primary-indicator-color-1,
    $primary-indicator-color-1,
    $primary-indicator-color-2,
    $primary-indicator-color-2
  ) !important;
  border-radius: 0.4rem !important;
  text-transform: none !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

.proposalButton:hover {
  background-position: -145% 0% !important;
  moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

.mobile-title-font-size {
  @media screen and (max-width: 768px) {
    font-size: 3rem !important;
  }
}

.mobile-content-font-size {
  @media screen and (max-width: 768px) {
    font-size: 1.7rem !important;
  }
}

.only-on-mobile {
  display: none !important;

  @media screen and (max-width: 768px) {
    display: unset !important;
  }
}

.only-not-large-screen {
  display: none !important;

  @media screen and (max-width: 1024px) {
    display: unset !important;
  }
}

.only-on-desktop {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

.only-not-small-screen {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.actCloseIcon {
  color: $primary-divider-color !important;
  cursor: pointer !important;
  float: right !important;
  font-size: 3rem !important;
}

#tckn-field-input-helper-text {
  display: none !important;
}

.MuiInput-underline.Mui-error:after {
  border-bottom: none !important;
}

#masked-number-field-input-helper-text {
  display: none !important;
}

.MuiAlert-root {
  font-size: 1.7rem !important;
}

.MuiMenu-paper {
  zoom: 80% !important;
}
