@import "../../../../style.scss";

.container {
  display: flex;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 630px;
  margin: 0 auto;
}

.productContainer {
  width: 275px;
  height: 275px;
  margin: 1rem 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  justify-self: center;
  display: grid;
  grid-template-rows: 6fr 1fr;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  transition: all 0.4s ease-in-out;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  animation: moveTopToBottom 1s linear;
}

@keyframes moveTopToBottom {
  0% {
    transform: translateY(-75px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.productContainer:hover {
  .productImgContainer {
    background-color: rgba($primary-color, 0.5);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .productNameContainer {
    transform: translateY(-236px);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    flex-direction: column;
  }

  .name {
    transform: translate(0, -250%);
  }

  .infoContainer {
    transform: translate(0, 300%);
  }
}

.productImgContainer {
  height: 235.7px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.productNameContainer {
  width: 275px;
  height: 275px;
  background: linear-gradient(to bottom, $primary-color, $primary-button-color);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 1.7rem;
  font-family: $primary-header-font;
  color: $primary-font-color-dk-bg;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 300;
  -moz-transition: height 0.4s;
  -ms-transition: height 0.4s;
  -o-transition: height 0.4s;
  -webkit-transition: height 0.4s;
  transform: translateX(0);
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.infoContainer {
  transition: all 0.4s ease-in-out;
  margin: 1rem 0;
}

.name {
  transition: all 0.4s ease-in-out;
  margin: 0.5rem 0;
}

.productLinkContainer {
  text-decoration: none !important;
}
