@import "../../../style.scss";

.carouselContainer {
  position: relative;
  height: auto;
  margin: 0 auto;
  z-index: 1;

  @media (max-width: 1040px) {
    height: 100%;
  }
  .contentContainer {
    width: 100%;
    height: 100%;
    display: flex !important;
    flex-direction: row !important;
    outline: none;
    justify-content: center;
    margin-top: 2rem;

    @media (max-width: 1040px) {
      flex-direction: column-reverse !important;
      margin-top: 0;
    }

    .textContainer {
      width: 40%;
      padding: 20px;

      @media (max-width: 1040px) {
        width: 100%;
        text-align: center;
        padding-bottom: 40px;
      }

      .titleContainer {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-right: 4rem;
        padding-bottom: 2rem;

        @media (max-width: 1040px) {
          margin: 0;
          padding-right: 0;
          padding-top: 0;
        }

        .title {
          color: white;
          font-size: 4rem;
          font-family: $primary-header-font;
          font-weight: 300;
          text-align: left;
          margin: 1rem 0;
          display: inline-block;
          line-height: 1.2;

          @media (max-width: 1040px) {
            width: 100%;
            text-align: center;
            padding: 2.25rem;
            padding-bottom: 0;
          }

          @media (max-width: 728px) {
            font-size: 3rem;
          }
        }
      }
    }

    .imgContainer {
      margin: 2rem 0;
      width: 40%;
      height: 450px;
      justify-self: start;
      align-self: center;
      border: 2px solid white;
      border-radius: 0.5rem;
      box-shadow: -1px 0px 10px 0.5px rgba(255, 255, 255, 0.2);
      -webkit-box-shadow: -1px 0px 10px 0.5px rgba(255, 255, 255, 0.2);
      -moz-box-shadow: -1px 0px 10px 0.5px rgba(255, 255, 255, 0.2);
      @media (max-width: 1040px) {
        width: 100%;
        margin: 0;
        border-radius: 0;
        border: none;
      }

      @media (max-width: 728px) {
        height: 100%;
      }

      .img {
        border-radius: 0.5rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.content {
  margin: 1rem 0;
  color: white;
  font-size: 2.2rem;
  font-family: $primary-content-font;
  font-weight: 300;

  @media (max-width: 728px) {
    font-size: 1.8rem;
  }
}

.divider {
  height: 2px;
  background-color: $primary-divider-color;
  margin: 0.5rem auto 2.5rem;
}

.playButtonContainer {
  position: absolute;
  padding: 0;
  border-radius: 40px;
  text-align: center;
  padding: 10px;
  bottom: 0;
  left: calc(4%);
  bottom: 46%;
  color: white;
  @media (max-width: 1040px) {
    display: flex !important;
    justify-content: center;
    flex-direction: row;
    vertical-align: middle;
    margin: auto;
    position: initial;
    padding-bottom: 2rem;
    max-width: 50px;
  }
}

.playButton {
  background: transparent;
  color: transparent;
  cursor: pointer;
  display: flex;
  height: 20px;
  width: 20px;
  border: 0.0625rem solid white;
  border-radius: 50%;
}

.playIcon {
  color: aliceblue !important;
  margin-left: 2px !important;
  margin-top: 2px !important;
  font-size: 1.4rem !important;
}
