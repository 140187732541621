//ROBOTO FONT
@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: local("Roboto"),
    url(./components/assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: local("Roboto"),
    url(./components/assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"),
    url(./components/assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: local("Roboto"),
    url(./components/assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: local("Roboto"),
    url(./components/assets/fonts/Roboto/Roboto-Black.ttf) format("truetype");
}

//POPPINS FONT
@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(./components/assets/fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("Poppins"),
    url(./components/assets/fonts/Poppins/Poppins-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(./components/assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"),
    url(./components/assets/fonts/Poppins/Poppins-SemiBold.ttf)
      format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(./components/assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: local("Poppins"),
    url(./components/assets/fonts/Poppins/Poppins-ExtraBold.ttf)
      format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"),
    url(./components/assets/fonts/Poppins/Poppins-Black.ttf) format("truetype");
}

//PRIMARY COLOR
$primary-color-light-3: #162a3b;
$primary-color-light-2: #162a3b;
$primary-color-light-1: #162a3b;
$primary-color: #162a3b;
$primary-color-dark-1: #162a3b;
$primary-color-dark-2: #162a3b;
$primary-color-dark-3: #162a3b;

//PRIMARY BUTTON COLOR
$primary-button-color: #1c5785;

//PRIMARY DIVIDER COLOR
$primary-divider-color: #28acd8;

//PRIMARY FONT COLOR
$primary-font-color: #707070;
$primary-title-color: #3b3b3b;
$primary-phone-color: rgb(66, 61, 61);
$primary-font-color-lg-bg: black;
$primary-font-color-dk-bg: white;
$primary-footer-color: #a2aab1;
$primary-contact-form-color: #707070;

//PRIMARY FONTS
$primary-header-font: "Poppins", sans-serif;
$primary-content-font: "Roboto", sans-serif;

//PRIMARY-BACKGROUND-COLOR
// $primary-bg-color: #ececec;
$primary-bg-color: #f5f5f5;
$primary-first-footer-bg: #e8f3fc;
$primary-second-footer-bg: #162a3b;

$primary-first-footer-color: #3b3b3b;

$primary-border-color: #162a3b;

$primary-institutions-card-bg: white;

$primary-card-name-bg: aliceblue;

$header-shadow: rgba(0, 0, 0, 0.5);

$navigation-color: #f4f7f6;

$primary-gradient: linear-gradient(to right, #1c5785 5%, #162a3b 95%);

//PRODUCT FORM
$primary-product-title-color: $primary-color;
$primary-product-form-color: $primary-color;
$primary-product-form-label-font-size: 1.6rem;
$primary-product-form-kvkk-font-size: 1.5rem;
$primary-product-form-kvkk-color: $primary-color;
$primary-product-form-container-margin: 0rem auto;
$primary-product-form-container-padding: 3rem 0;

$primary-indicator-color-1: #277ab9;
$primary-indicator-color-2: #0c385f;
