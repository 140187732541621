@import "../../../../style.scss";

.magic-dots.slick-dots {
  bottom: -10px;

  @media screen and (max-width: 728px) {
    bottom: -5px;
  }
}

.magic-dots.slick-dots li button:before {
  color: $primary-color;
  transition: all 0.3s ease-in-out;
}

.magic-dots.slick-dots li.slick-active button:before {
  color: $primary-color;
  opacity: 1;
}

.slick-slider {
  height: 100%;
}

.slick-prev {
  left: -35px;
}

.slick-next {
  right: -35px;
}

.slick-prev, .slick-next {
  width: 30px;
  height: 30px;
  top: 45%;
}

.slick-prev:before, .slick-next:before {
  color: $primary-color;
  font-size: 30px;
}
