@import "../../../../style.scss";

.contentContainer {
  font-size: 1.7rem !important;
  color: $primary-font-color;
  font-family: $primary-content-font;
  width: 100%;
  font-weight: 400 !important;
  padding: 0 2rem 3rem 2rem;
}
