@import "../../../../../style.scss";

.container {
  max-width: 1400px;
  margin: $primary-product-form-container-margin;
  padding: $primary-product-form-container-padding;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.imgMainContainer {
  height: 500px;
  width: 500px;
  margin-bottom: 6rem;
  border-radius: 0.5rem;
}

.imgContainer {
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -webkit-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  -moz-box-shadow: -1px 0px 20px 0.5px rgba(89, 89, 89, 0.4);
  border: 2px solid white;
}

.backContainer {
  width: 100px;
}

.iconButton {
  padding: 0 !important;
}

.backIcon {
  font-size: 4rem !important;
  cursor: pointer;
  color: $primary-product-form-color;
}

.formContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.titleContainer {
  font-family: $primary-header-font;
  font-size: 2.5rem;
  font-weight: bold;
  color: $primary-title-color;
  margin-bottom: 2rem;
}

.inputContainer {
  margin-bottom: 2.5rem;
}

.plateContainer {
  display: flex;
}

.plateCodeContainer {
  width: 25%;
  margin-right: 3rem;
}

.plateTextContainer {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.label {
  font-family: $primary-content-font;
  font-size: $primary-product-form-label-font-size;
  color: $primary-product-form-color;
  margin-bottom: 0.5rem;
}

.textField {
  width: 100% !important;
  font-size: $primary-product-form-label-font-size !important;
}

.select {
  width: 100% !important;
  font-size: $primary-product-form-label-font-size !important;
}

.menuItem {
  font-size: $primary-product-form-label-font-size !important;
}

.reCaptchaContainer {
  margin-bottom: 2rem;
}

.kvkkLinkContainer {
  margin-bottom: 3rem;
  font-size: $primary-product-form-kvkk-font-size;
}

.kvkkLink {
  color: $primary-product-form-kvkk-color;
}

.buttonContainer {
  margin: 0 auto;
  max-width: 200px;
  margin-left: 5rem;
}

.proposalButton {
  // background-color: $primary-button-color !important;
  color: white !important;
  min-width: 100px !important;
  font-size: 1.5rem !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  backface-visibility: hidden !important;
  position: relative !important;
  display: inline-block !important;
}

.proposalButton::before,
.proposalButton::after {
  content: "" !important;
  position: absolute !important;
  display: block !important;
  height: 100% !important;
  width: 100% !important;
  border-radius: 0.4rem !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  font-family: $primary-content-font;
  background: $primary-indicator-color-1 !important;
  background: -moz-linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  ) !important;
  background: -webkit-linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  ) !important;
  background: linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  ) !important;
  z-index: -1 !important;
}

.proposalButton::after {
  opacity: 0 !important;
  background: $primary-indicator-color-1 !important;
  transition: opacity 0.3s ease-in-out !important;
}

.proposalButton:hover::after {
  opacity: 1 !important;
}
