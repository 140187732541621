@import "../../../../style.scss";

.contentContainer {
  font-size: 1.7rem !important;
  color: $primary-font-color;
  font-family: $primary-content-font;
  width: 100%;
  font-weight: 400 !important;
  padding: 0 2rem 3rem 2rem;
}

.backButton {
  text-transform: none !important;
  font-family: $primary-header-font !important;
  font-weight: 400 !important;
  background: $primary-button-color !important;
  color: white !important;
  min-width: 100px !important;
  font-size: 1.7rem !important;
  box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -webkit-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  -moz-box-shadow: -1px 0px 10px 0.5px rgba(89, 89, 89, 0.2);
  backface-visibility: hidden !important;
  position: relative !important;
  display: inline-block !important;
}
