@import "../../style.scss";

$title-color: #fefefe !important;

.footer {
  width: 100%;
  position: relative;
  height: auto;
  color: $primary-footer-color !important;
  font-weight: normal !important;
}

.mainContainer {
  max-width: 1500px;
  margin: 0 auto;
}

.firstContainer {
  display: grid;
  grid-template-columns: 3fr 1fr;
  // max-width: 1500px;
  background-color: $primary-first-footer-bg;
  margin: 0 auto;
  grid-gap: 4rem;
  padding: 4rem 0;
}

.secondMainContainer {
  background: $primary-gradient;
}

.secondContainer {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  max-width: 1500px;
  margin: 0 auto;
  padding: 4rem 0 2rem 0;
}

.mapContainer {
  width: 100%;
  height: 400px;
  justify-self: start;
  align-self: center;
  border-radius: 0.5rem;
  padding: 0 4rem;
}

.contactContainer {
  justify-self: start;
  align-self: center;
  color: $primary-first-footer-color;
  font-size: 2.5rem;
  text-align: left;
  padding: 0 2rem;
}

.contactTitle {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 2rem;
  font-family: $primary-header-font;
}

.phoneContainer {
  font-size: 1.7rem;
  margin-bottom: 2rem;
}

.mailContainer {
  font-size: 1.7rem;
  margin-bottom: 2rem;
  font-weight: 300;
}

.contactContentContainer {
  font-size: 1.7rem;
  margin-bottom: 2rem;
  font-weight: 300;
  display: flex;
  justify-content: center;
}

.socailsContainer {
  display: flex;
}

.socialIconContainer {
  font-size: 2rem !important;
  margin: 0 2px;
  cursor: pointer;
}

.socialIcon {
  font-size: 3rem !important;
}

.contactFormContainer {
  justify-self: start;
}

.contactFormTitle {
  padding: 2rem 2rem;
  font-size: 3rem;
  color: $primary-footer-color;
  font-family: $primary-header-font;
}

.sitemapContainer {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  padding: 0 2rem;
}

.sitemapTitle {
  font-size: 3rem;
  color: $primary-footer-color;
  font-family: $primary-header-font;
}

.sitemapItemTitleContainer {
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 500;
  color: $title-color;
  font-family: $primary-header-font !important;
}

.sitemapItem {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem !important;
  font-family: $primary-header-font !important;
}

.sitemapLink {
  text-decoration: none !important;
  color: $primary-footer-color !important;
}

.sitemapTitleLink {
  text-decoration: none !important;
  color: $title-color;
}

.sitemapLink:hover {
  text-decoration: underline !important;
}

.infoContainer {
  padding: 0 2rem;
}

.logosContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.7rem 0;
}

.logoChild {
  height: 5.5vh;
  position: relative;
  margin: 0 2rem;
  padding: 5px;
  min-height: 5rem;
}

.agencyLogo {
  border: 1px solid rgba(255, 255, 255, 0.425);
  border-radius: 2px;
}

.logo {
  object-fit: cover;
  height: 100%;
  cursor: pointer;
}

.infoContainerAgencyNameContainer {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  font-family: $primary-header-font !important;
  padding-left: 3rem;
}

.infoContainerContent {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  font-family: $primary-header-font !important;
  padding-left: 3rem;
}

.verticalLine {
  border-left: 2px solid #d1d1d1;
  height: 70px;
}

.socialLink {
  color: inherit;
}

.mail {
  display: inline;
  cursor: pointer;
  font-size: 1.7rem !important;
  font-weight: 300 !important;
  font-family: $primary-content-font;
  text-transform: none !important;
}

.phone {
  display: inline;
  cursor: pointer;
  font-size: 1.7rem !important;
  font-weight: 300 !important;
  font-family: $primary-content-font;
}

.location {
  font-size: 2rem !important;
  margin-right: 1rem !important;
}

.sitemapMainContainer {
  margin: 2rem 4rem;
  align-self: flex-start;
}

.rightsMainContainer {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}

.rightsContainer {
  border-top: 2px solid hsla(0, 0%, 81%, 0.16);
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rightItem {
  padding: 1rem 0;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  font-family: $primary-header-font;
  text-transform: none !important;
}

.rightItemLink {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  font-family: $primary-header-font;
  text-transform: none !important;
  color: $primary-footer-color !important;
  text-decoration: none;
}

.rightItemLink:hover {
  text-decoration: underline;
}

.upContainer {
  border-bottom: 2px solid hsla(0, 0%, 81%, 0.16);
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.waveContainer {
  position: relative;
  height: 100px;
}

@media screen and (max-width: 1225px) {
  .firstContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .secondContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .contactContainer {
    text-align: center;
    width: 100%;
  }

  .socailsContainer {
    justify-content: center;
  }
}

@media screen and (max-width: 900px) {
  .rightsContainer {
    justify-content: center;
  }
}

@media screen and (max-width: 574px) {
  .secondContainer {
    text-align: center;
  }
}
