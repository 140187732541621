@import "../../../../style.scss";

.saleContainer {
  margin: $primary-product-form-container-margin;
  padding: $primary-product-form-container-padding;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 870px) {
    flex-direction: column;
  }
}

.saleFormContainer {
  display: flex;
  overflow: hidden;
  margin: 0 auto;

  direction: ltr;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  @media screen and (max-width: 870px) {
    flex-direction: column;
  }
}

.saleFormContainer::-webkit-scrollbar {
  display: none;
}

.saleFormContainer {
  max-width: 860px;
  @media screen and (max-width: 1380px) {
    max-width: 645px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1220px) {
    max-width: 645px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1040px) {
    max-width: 430px;
    margin: 0 auto;
  }
}

.saleLabel {
  font-family: $primary-content-font;
  font-size: $primary-product-form-label-font-size;
  color: $primary-product-form-color;
  margin-bottom: 0.5rem;
}

.saleInputContainer {
  width: 215px;
  min-width: 215px;
  max-width: 215px;
  padding: 0 1rem;

  @media screen and (max-width: 870px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 1rem 0;
  }

  .MuiFormControlLabel-root {
    align-items: flex-start !important;
  }
}

.saleSelect {
  width: 100% !important;
  font-size: $primary-product-form-label-font-size !important;
}

.salePlateContainer {
  display: flex;
}

.salePlateCodeContainer {
  width: 35%;
  margin-right: 2rem;
}

.salePlateTextContainer {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.saleReCaptchaContainer {
  padding-top: 1rem;
}

.saleReCaptcha {
  @media screen and (max-width: 870px) {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.saleKvkkLinkContainer {
  padding: 0 1rem;
  padding-top: 1.5rem;
  font-size: $primary-product-form-kvkk-font-size;
  width: 215px;
  min-width: 215px;
  max-width: 215px;
  @media screen and (max-width: 870px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 1rem 0;
    padding-top: 0;
  }
}

.saleButtonContainer {
  margin-top: 1rem;
  width: 215px;
  min-width: 215px;
  max-width: 215px;
  text-align: center;
  @media screen and (max-width: 870px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.navigateIconContainer {
  cursor: pointer;
  padding: 0 2rem;
  display: flex;
  align-items: center;
}

.navigateIconContainer:first-child {
}

.navigateIconContainer:last-child {
}

.navigateIcon {
  font-size: 3.5rem !important;
  color: $primary-font-color-dk-bg !important;
  background: $primary-indicator-color-1;
  background: -moz-linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  background: linear-gradient(
    45deg,
    $primary-indicator-color-1 0%,
    $primary-indicator-color-2 100%
  );
  border-radius: 50%;
}

.navigationAnimation {
  animation: shake-animation 4s ease infinite !important;
  transform-origin: 50% 50% !important;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.contentArrowIcon {
  color: $primary-indicator-color-1 !important;
  background: transparent;
  margin-bottom: 2rem;
}

.disabledNavigationAnimation {
  color: gray !important;
}
